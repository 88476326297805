import React from 'react'

const TopUpperSection = ({text, img='/images/months/monthly-cover-1.png'}) => {
  return (
    <div className='top-section'>
      <div className={text&&'overlayer'}>
        <div className='text-container'>
          <div className='text'>
            <span >{text}</span>
          </div>
        </div>
      </div>
          <img className='top-img' src={img} />
      
      </div>
  )
}

export default TopUpperSection