import React from 'react'
import Vedio from '../Resable/Vedio'

const Vedios = () => {
  return (
      <div className='grid-container'>
           <Vedio songName='للي ' fileName='3.mp4' poster='https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg' />
          <Vedio songName='في عشق البنات  ' fileName='4.mp4' poster='https://i.ytimg.com/vi/dRh-XXDfe_M/maxresdefault.jpg' />
          <Vedio songName='للي ' fileName='3.mp4' poster='https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg' />
           <Vedio songName='في عشق البنات  ' fileName='4.mp4' poster='https://i.ytimg.com/vi/dRh-XXDfe_M/maxresdefault.jpg' />
       
    </div>
  )
}

export default Vedios