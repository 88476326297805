import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { MdVideoLibrary } from 'react-icons/md'
import { BsSearch } from 'react-icons/bs'
import { TbPlaylist } from 'react-icons/tb'
import {FiMoreHorizontal} from 'react-icons/fi'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className='footer'>
          <Link to='/videos'>
            <div className='option'>
                  <span>
                <MdVideoLibrary />
                  </span> 
            </div>
          </Link>
          
          <div className='option'>
            <span>
           <BsSearch />
            </span> 
          </div>
      <Link to='/'>
          <div className='option'>
              <span>
              <AiOutlineHome />
              </span> 
          </div>
      </Link> 
      <Link to='/playlists'>
          <div className='option'>
              <span>
             <TbPlaylist />
              </span> 
          </div>
      </Link>  
          <div className='option'>
            <span>
           <FiMoreHorizontal />
            </span> 
          </div>
    </div>
  )
}

export default Footer