import React, {useState} from 'react'
import Footer from '../Resable/Footer'
import Header from '../Resable/Header'
import Player from '../TopTones/Player'
import Song from '../TopTones/Song'
import TopUpperSection from '../TopTones/TopUpperSection'

const Gener = () => {
  const [playerSong, setPlayerSong] = useState('يا حبيبي')
  const [playerArtist, setPlayerArtist] = useState('عبد الفتاح الجريني')
  const [playerFile, setPlayerFile] = useState('AbdelFatahGrini-YaHabibi')
  
  const changeSong = (song, artist, file) => {
    setPlayerSong(song)
    setPlayerArtist(artist)
    setPlayerFile(file)
  }

  return (
      <div className='top-tones genre-page'>
    <Header />
    <div className='body'>
    <TopUpperSection text='Arabic pop' img='/images/playlists/icon-2.png'/>
        <div className='grid'>
    <button className='orange'>Shuffle Play</button>
    <button className='gray'>Follow Playlist</button>
  </div>
  <Player songName={playerSong} artistName={playerArtist} fileName={playerFile} />

              <Song changeSong={changeSong} img={'https://i.ytimg.com/vi/w2ppLXg7Onk/maxresdefault.jpg'} songName={'يا حبيبي'} artistName={"عبد الفتاح الجريني"} fileName={'AbdelFatahGrini-YaHabibi'} />
              <Song changeSong={changeSong}   img = {'https://i.ytimg.com/vi/T0AA8GvHzhY/maxresdefault.jpg'} songName = {'انت اختيار '} artistName = {"تامر حسني "} fileName='TamerHosny-EntaEkhtyar'/>
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/svm9_UmQRls/maxresdefault.jpg"} songName = {"قلبي حبك جدا"} artistName = {"محمد حماقي"} fileName='Hamaki-AlbyHabbakGeddan' />
        <Song  img = {"https://i.ytimg.com/vi/ylBly0NfCLU/maxresdefault.jpg"} songName = {"غلبان"} artistName = {"اصالة"} />
        <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName = {"البخت"} artistName = {"ويجز"} fileName='Wegz-ElBakht' />
        <Song  img = {"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName = {"خطيرة"} artistName = {"رامي صبري"} />
              <Song img={"https://i.ytimg.com/vi/ULM9GvvJ370/maxresdefault.jpg"} songName={"أنا غلطان"} artistName={"بهاء سلطان"} />
              <Song  img = {"https://i1.sndcdn.com/artworks-000098647167-veq6zs-t500x500.jpg"} songName = {"الليل الهادي"} artistName = {"محمد فؤاد"} />
    </div>
    <Footer/>
</div>
  )
}

export default Gener