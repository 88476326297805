import React from 'react'
import {AiOutlineSearch} from 'react-icons/ai'
const UpperSection = () => {
  return (
      <div className='upper-section'>
          <span className='title'><span>Rbt</span> Music</span>
          <div className='search'>
              <input type='text' placeholder='Search' /> 
              <span className='search-icon'><AiOutlineSearch/></span>
          </div>
         
          
    </div>
  )
}

export default UpperSection
