import React, { useRef,useState, useEffect } from 'react'
import { BsThreeDots } from 'react-icons/bs'
import { AiOutlineHeart } from 'react-icons/ai'



// use ref  function 
function useOutsideAlerter(ref,setShowlist) {
    useEffect(() =>{
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowlist(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      
    }}, [ref]);
  }
const Song = ({img = 'https://i.ytimg.com/vi/T0AA8GvHzhY/maxresdefault.jpg', songName = 'انت اختيار',fileName, artistName = 'تامر حسني',changeSong }) => {
    const [showlist, setShowlist] = useState(false)
    // close list when click any where
   const wrapperRef = useRef(null);
   useOutsideAlerter(wrapperRef,setShowlist);
    return (
      <div className='song-line' onClick={()=>changeSong(songName, artistName,fileName )}>
          <img src={img} />
          <div className='song-details'>
             <span className='song-name'>{songName}</span> 
              <br />
              <span className='artist-name'>{artistName}</span> 
              
            </div>
            {showlist && <div className='list'  ref={wrapperRef}>
                <div className='list-option'><span className='icon'><AiOutlineHeart /></span> Like</div>
                <div className='list-option'>Add to playlist</div>
                <div className='list-option'>Share</div>
                <div className='list-option'>Assign as call tone</div>
            </div>}
          <span className='oposite' onClick={()=>{setShowlist(!showlist)}} ref={wrapperRef}><BsThreeDots /></span>
      </div>
  )
}

export default Song