import React from 'react'
import Song from '../Resable/Song'


import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual
} from "swiper/core";
// import "swiper/swiper-bundle.css";
// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import MostRecentSongs from './MostRecentSongs';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);



const MostRecent = () => {
  const slides = [
    <SwiperSlide >
      <MostRecentSongs  img = {"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName = {"خطيرة"} artistName = {"رامي صبري"}/>
   </SwiperSlide>,
    <SwiperSlide >
     <MostRecentSongs   img = {"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName = {"البخت"} artistName = {"ويجز"}   />
    </SwiperSlide>,
     <SwiperSlide >
     <MostRecentSongs  img = {'https://i.ytimg.com/vi/bvbhfgXk_pY/maxresdefault.jpg'} songName = {'الفترة دي'} artistName = {"تامر عاشور"} />
    </SwiperSlide>,
      <SwiperSlide >
      <MostRecentSongs   img = {"https://i.ytimg.com/vi/svm9_UmQRls/maxresdefault.jpg"} songName = {"قلبي حبك جدا"} artistName = {"محمد حماقي"} />
    </SwiperSlide>,
       <SwiperSlide >
       <MostRecentSongs  img = {"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName = {"البخت"} artistName = {"ويجز"} />
    </SwiperSlide>,
         <SwiperSlide >
         <MostRecentSongs img = {"https://i.ytimg.com/vi/ULM9GvvJ370/maxresdefault.jpg"} songName = {"أنا غلطان"} artistName = {"بهاء سلطان"} />
    </SwiperSlide>,
          <SwiperSlide >
          <MostRecentSongs  img = {'https://i.ytimg.com/vi/bvbhfgXk_pY/maxresdefault.jpg'} songName = {'الفترة دي'} artistName = {"تامر عاشور"} />
     </SwiperSlide>,

    
  ];

  
  
  return (
      <div className='songs-section most-recent'>

      <Swiper
      id="swiper"
      virtual
      slidesPerView={1}
      // slidesPerColumn={2}
      // slidesPerColumnFill="row"
      spaceBetween={30}
      // slidesPerGroup={2}
      autoplay
      // loop
      // onReachEnd={() => {
      //   console.log("reach end");
      //   const tmp = slides.unshift();
      //   slides.push();
      // }}
      navigation
      pagination
    >
      {slides}
    </Swiper>
          
    </div>
  )
}

export default MostRecent