import React from 'react'
import { Link } from 'react-router-dom'
import Gener from './Gener'

const Genres = () => {
  return (
      <div className='genres'>
        
      <Link to='/genre'><Gener text={<>Arabic  Pop</>} img={'/images/playlists/icon-2.png'} /></Link>
      <Link to='/genre'><Gener text={ <>Arabic  Rap</>}  img={'/images/playlists/icon-5.png'}/></Link>
      <Link to='/genre'><Gener text={ <>Shaaby  Music</>}  img={'/images/playlists/icon-3.png'}/></Link>
   
      <Link to='/genre'><Gener text={<>Arabic  Pop</>} img={'/images/playlists/icon-4.png'} /></Link>
      <Link to='/genre'><Gener text={<>international music</>} img={'/images/playlists/icon-1.png'} /></Link>
      <Link to='/genre'><Gener text={<>international music</>} img={'/images/playlists/icon-6.png'} /></Link>
          
         
    </div>
  )
}

export default Genres