import React from 'react'
import { Link } from 'react-router-dom'
import Song from '../Resable/Song'

const Trends = () => {
  return (
    <div className='songs-section'>
    <div className='title'>Trending Now </div>
          <div class="grid-container">
              <Song img={"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName={"خطيرة"} artistName={"رامي صبري"} />
              <Song  img = {"https://i.ytimg.com/vi/ULM9GvvJ370/maxresdefault.jpg"} songName = {"أنا غلطان"} artistName = {"بهاء سلطان"} />
              <Song  img = {"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName = {"البخت"} artistName = {"ويجز"} />
              <Song  img = {"https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg"} songName = {"للي"} artistName = {"محمد منير"} />
             
       
              <Song   />
              <Song  img = {'https://i.ytimg.com/vi/bvbhfgXk_pY/maxresdefault.jpg'} songName = {'الفترة دي'} artistName = {"تامر عاشور"} />
              <Song  img = {"https://i.ytimg.com/vi/svm9_UmQRls/maxresdefault.jpg"} songName = {"قلبي حبك جدا"} artistName = {"محمد حماقي"} />
    </div>
</div>
  )
}

export default Trends