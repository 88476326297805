import React from 'react'
import Footer from '../Resable/Footer'
import Header from '../Resable/Header'
import AssignedSection from './AssignedSection'
import Public from './Public'
import {BsFillCameraFill} from 'react-icons/bs'
const Profile = () => {
  return (
      <div className='profile'>
      <Header profile={ true} />
        <div className='upper-section'>
          <span className='title'>Orange Music</span>
          <div className='blak'>My Profile</div>
           
          
            <div className='img'>
          <img src='https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png' />
          <div className='text'>
          <span className='icon'>
            <  BsFillCameraFill /> 
            </span>
           { ' '+ 'upload' }
         </div>
        
            </div>
        </div>
          <div className='assigned-sections'>
        <Public />
        <h2>Personal Call Tones</h2>
        <AssignedSection sectionName='Family' song='البيت وناسه' artistName='Mohamed Foaad' /> 
        <AssignedSection sectionName='Work' song='الغسالة ' artistName="Wegz" />  
        <AssignedSection sectionName='Love' song='ياحبيبي ' artistName= "Abdelftah El Greny"/>  
      </div>
      <button className='add-section'>
         Add New Group
      </button>
          <Footer />
    </div>
  )
}

export default Profile