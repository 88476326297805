import React, { useState } from 'react'
import 'react-jinke-music-player/assets/index.css'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import Header from '../Resable/Header'
import Footer from '../Resable/Footer'
import Song from '../TopTones/Song'
import { GiMusicSpell } from 'react-icons/gi'
import { BsDisc } from 'react-icons/bs'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
const AlbumPage = ({ songName , artistName , fileName='asala_shayfa_fyk'}) => {
    
       
    const [songTrack, setSongTrack] = useState(songName + '(Full Track)')
    const [songType, setSongType] = useState('mp3')
    const [song, setSong] = useState(fileName + '.mp3')

    const plyrProps = {
        source: {
            type: 'audio',
            title: 'Player',
          
            sources: [
              {
                src: `/mp3/${song}`,
                type: `audio/${songType}` ,
                },
               
             
            ],
          
        },
 
        options: {
            "controls": ['play-large', 'play'],
            "autoplay":true,
            
        }, // https://github.com/sampotts/plyr#options
        // Direct props for inner video tag (mdn.io/video)
       
     
        // loop: true,
        // playing: true,
        
        

    }
    return (
        <>
                <Header />
              <div className='player-page'>
            
                <div className='player-with-image '>
                    <img src='https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg' />
                    <div className='overlayer'></div>
                    <div className='player'>
                        <Plyr  {...plyrProps} enabled={true} /> 
                        <div className='song-info'>
                            Asala -  Shayfa feek 
                        </div>
              
                        
                    </div>

                    
                </div>
       {/* start icons actions  */}
       <div className='icons-actions'>
                        <span className='icon' onClick={() => { setSong(fileName + 1 + '.wav'); setSongTrack(songName + ' tone 1'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone1</span>
                        </span>
                        <span className='icon' onClick={() => { setSong(fileName + 2 + '.wav'); setSongTrack(songName + ' tone 2'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone2</span>
                        </span>
                        {/* <span className='icon'>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>tone3</span>
                        </span> */}
                        <span className='icon' onClick={() => { setSong(fileName + '.mp3'); setSongTrack(songName + ' (Full Track)'); setSongType('mp3')}}>
                            <BsDisc />
                            <br />
                            <span className='tone-type'>Full Track</span>
                        </span>
                        <span className='icon oposite'>
                            <MdOutlinePhonelinkRing />
                            <br />
                            <span className='tone-type'>Assign</span>
                    </span>
                    </div>
                       {/* end icons actions  */}
                <div className='traks-num'>11 Tracks </div>
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" ghalban "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" ana hora "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={"farha w bs "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" kfokim "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" fkdt nafsy "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" blsalama "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" elymtdayk yfarik "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={"mny wfy "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" garhy flhoa"} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={"zi elmo'agzat "} artistName={"Asala"} />
                <Song img={"https://i.ytimg.com/vi/BMtPS_fb07Y/maxresdefault.jpg"} songName={" kol yoom el rehla "} artistName={"Asala"} />
                
             
                
              </div>
            <Footer />
      </>
    
  )
}
export default AlbumPage