import React from 'react'
import { GoTriangleRight } from 'react-icons/go'
import { IoMdMusicalNotes } from 'react-icons/io'
import { TbEdit } from 'react-icons/tb'
import { MdDelete } from 'react-icons/md'
import {RiAddCircleFill} from 'react-icons/ri'
import { Link } from 'react-router-dom'
const AssignedSection = ({sectionName, song, artistName}) => {
  return (
   
       <div className='assigned'>
          <div className='title'>
            <span className='icon'>
                <GoTriangleRight />  
            </span>
          {sectionName} 
        <span className='icon oposite'><MdDelete /></span>
        &nbsp; <span className='icon oposite'><TbEdit /></span>
        </div>
      <Link to='/player'>
          <div className='song'>
         
              &nbsp;
                <span>
                   {artistName}
                </span>
              &nbsp;
              . .  
            &nbsp;
            <span className='icon'><IoMdMusicalNotes /></span>
            &nbsp;
                <span>
                  
                    {song} 
          </span>
          &nbsp;  &nbsp; <span className='icon '><TbEdit /></span>
           
         
         
          
        </div>
      </Link>
      <div className='numbers'>
        - 01126214650  &nbsp; <span className='icon '><MdDelete /></span>
        <br/>
       - 01126214650  &nbsp; <span className='icon '><MdDelete /></span>
        <br/>
       - 01126214650  &nbsp; <span className='icon '><MdDelete /></span>
        <br />
       - 01126214650  &nbsp; <span className='icon '><MdDelete /></span>
        <br />
        <div className='add'> <span className='icon '><RiAddCircleFill /></span>Add Number</div>
      </div>
    </div>
  )
}

export default AssignedSection