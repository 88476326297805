import React from 'react'
import { Link } from 'react-router-dom'
import Song from '../Resable/Song'
const BlastFromThePast = () => {
  return (
    <div className='songs-section'>
    <div className='title'>  Blast From The Past </div>
     <div class="grid-container">
        <Song  img = {"https://img.youm7.com/ArticleImgs/2022/3/29/75067-75067-%D8%A7%D9%84%D8%B9%D9%86%D8%AF%D9%84%D9%8A%D8%A8-%D8%B9%D8%A8%D8%AF-%D8%A7%D9%84%D8%AD%D9%84%D9%8A%D9%85-%D8%AD%D8%A7%D9%81%D8%B8.jpg"}  artistName = {""} songName= {"AbdelHaleem Hafez"} />
        <Song  img = {"https://alwafd.news/images/thumbs/828/news/7f24021f1f9eb8e84addddfe3c20bcb0.jpg"} artistName = {""} songName= {"Om Khalthom"} />
              <Song img={"https://gate.ahram.org.eg/Media/News/2018/8/15/2018-636699350424500404-450.jpg"} artistName={""} songName= {"Mohamed Fawzy "} />
        <Song  img = {"https://i1.sndcdn.com/artworks-000017457272-9g1w54-t500x500.jpg"} artistName = {""}  songName={"Mohamed AbdelWahab"} />
        <Song  img = {'https://www.sayidaty.net/sites/default/files/styles/900_scale/public/17/05/2016/1463478675_5.jpg'} artistName = {""} songName= {"Warda"} />
        <Song  img = {"https://aliwaa.com.lb/files/base/portal/content-main-image/c47e2b4e-4071-4884-8209-f0791a5befe7.jpg"} artistName = {""} songName= {"Nagat El Saghera"} />
       
</div>
</div>
  )
}

export default BlastFromThePast