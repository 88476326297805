import logo from './logo.svg';
import './App.scss';
import { Route, Routes ,BrowserRouter as Router} from 'react-router-dom'
import Home from './pages/homePage/Home';
import Profile from './pages/profile/Profile';
import TopTones from './pages/TopTones/TopTones';
import ScrollToTop from './ScrollTp';
import Gener from './pages/GenrePage/Gener';
import Playlists from './pages/Playlists/Playlists';
import Artist from './pages/Artist/Artist';
import Player from './pages/Player/Player';
import AlbumPage from './pages/AlbumPage/AlbumPage';
import Religon from './pages/religions/Religon';
import Coptics from './pages/religions/Coptics';
import VediosPage from './pages/VediosPage';

  
function App() {
  
  return (
    <div >
   
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/profile/" element={<Profile />} exact />
          <Route path="/toptone/" element={<TopTones />} exact />
          <Route path="/genre/" element={<Gener />} exact />
          <Route path="/Playlists/" element={<Playlists />} exact />
          <Route path="/Artist/" element={<Artist />} exact />
          <Route path="/player" element={<Player />} exact />
          <Route path="/album" element={<AlbumPage />} exact />
          <Route path="/religion" element={<Religon />} exact />
          <Route path="/coptic" element={<Coptics />} exact />
          <Route path="/videos" element={<VediosPage />} exact />
               
        </Routes>
    </Router>
    </div>
  );
}

export default App;
