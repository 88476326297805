import React from 'react'
import { Link } from 'react-router-dom'

const ArtistCircle = ({img='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkIjUJoQAjlstqm6LXhr--AmeWgB94e2u_zA&usqp=CAU'}) => {
  return (
      <Link to='/Artist'>
           <div className='artist'>
                <img src={img}  />
                {/* <div>رامي صبري</div> */}
             </div>
      </Link> 
  )
}

export default ArtistCircle