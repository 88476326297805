import React from 'react'

const ArtistTop = () => {
  return (
    <div className='Artist-info'> 
        <div className='img'>
              <img src='https://storage.googleapis.com/jarida-cdn/images/1653320027534964800/1653320263000/1280x960.jpg' />
          </div>
          <div className='artistName'>
            محمد منير
          </div>
        
    </div>
  )
}

export default ArtistTop