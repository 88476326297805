import React from 'react'
import Topmonth from './TopMonth'

const TopMonthlySection = () => {
  return (
    <div className='monthly-section'>
    
      <Topmonth img='/images/months/MONTHLY-icon-2.png' />
      <Topmonth img='/images/months/MONTHLY-icon-3.png' />
      <Topmonth img='/images/months/MONTHLY-icon-4.png' />
      <Topmonth img='/images/months/MONTHLY-icon-5.png'  />
      <Topmonth img='/images/months/MONTHLY-icon-6.png' />
      <Topmonth img='/images/months/MONTHLY-icon-7.png'  />
      <Topmonth img='/images/months/MONTHLY-icon-8.png' />

    </div>
  )
}

export default TopMonthlySection