import React from 'react'
import { Link } from 'react-router-dom'

const Album = ({albumName='باب الجمال', date='2020', img='https://i.ytimg.com/vi/ldhi9BQxEYo/maxresdefault.jpg'}) => {
  return (
    <Link to='/album'>
         <div className='album'>
            <img src={img} />
            <div className='album-name'>{ albumName}</div>
            <div className='date'>{ date}</div>
            
        </div>
    </Link>
      
  )
}

export default Album