import React from 'react'
import { Link } from 'react-router-dom'

const Song = ({ img = 'https://i.ytimg.com/vi/T0AA8GvHzhY/maxresdefault.jpg',songName = 'انت اختيار', artistName = 'تامر حسني' }) => {
  return (
    <div className='song'>
       <Link to='/player'>
      <div className='overlayer'>
        <div className='song-name-container'>
          <div className='song-name'>
            <span>{songName}</span></div>
        </div>
      </div>
     <img src={img} />
      <p>
      <div className='artist-name'>{ artistName}</div>
         
      </p>
      </Link>   
        
    </div>
  )
}

export default Song