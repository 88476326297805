import React from 'react'
import { Link } from 'react-router-dom'

const Topmonth = ({img}) => {
  return (
    <Link to='/toptone'>
       <div className='month'>
          <img src={img} />
        
       </div>
    </Link>
     
  )
}

export default Topmonth