import React from 'react'
import { GoTriangleRight } from 'react-icons/go'
import { IoMdMusicalNotes } from 'react-icons/io'
import { TbEdit } from 'react-icons/tb'
import {MdDelete} from 'react-icons/md'
import { Link } from 'react-router-dom'
const Public = () => {
  return (
      <div className='assigned'>
          <div className='title'>
            <span className='icon'>
                <GoTriangleRight />  
            </span>
       Public Call Tone
        <span>

        </span>
      </div>
      <Link to='/player'>
          <div className='song'>
         
              &nbsp;
                <span>
                    Mohamed Hamaki 
                </span>
              &nbsp;
              . .  
            &nbsp;
            <span className='icon'><IoMdMusicalNotes /></span>
            &nbsp;
                <span>
                  
                    قلبي حبك جدا  
              </span>
               <span className='icon '><TbEdit /></span>
        </div>
        </Link>
         
          
      </div>
  )
}

export default Public