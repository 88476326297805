import React from 'react'
import Footer from './Resable/Footer'
import Header from './Resable/Header'
import Vedio from './Resable/Vedio'
import {AiOutlineSearch} from 'react-icons/ai'
const VediosPage = () => {
  return (
      <div className='videos-page'>
          <Header />
         
     <div className='videos-container'>
      <div className='title'>Music Videos</div>
          <div className='search'>
              <input type='text' placeholder='Search for Videos' /> 
              <span className='search-icon'><AiOutlineSearch/></span>
          </div>
         
        <Vedio varrrr='1' songName={<> &nbsp;  - صح صح </>} artistName='نانسي عجرم'poster='https://i.ytimg.com/vi/exp_54O_5vU/hqdefault.jpg' fileName='6.mp4' img='https://media.elcinema.com/uploads/_315x420_3edb21070e10de46d0dfdecf6939d0c124ced3445f38432419ab21f8df2a751f.jpg' />
        <Vedio varrrr='2' songName={<> &nbsp;  - وسع وسع</>} artistName='احمد سعد' fileName='5.mp4'poster='https://i1.sndcdn.com/artworks-lGhILqVIPizqVgro-ypy23g-t500x500.jpg' img='https://media.elcinema.com/uploads/_315x420_2dc38d529fb43f678c0d74edc6761b2368aa96a764d04b6bc871a01494d02c53.jpg' />
        <Vedio varrrr='3' songName={<> &nbsp;  - الحلق</>} artistName='سعد لمجرد' poster='https://files.elfann.com/imagine/pictures_728_400/7407031_1662099954.jpg' fileName='7.mp4' img='https://www.hiamag.com/sites/default/files/styles/1000xauto/public/article/18/01/2021/10119721-681890264.jpeg?itok=sbgmz3l8' />
      
           <Vedio varrrr='4' songName={<> &nbsp;  - البخت </>} artistName='ويجز' poster='https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg'  fileName='1.mp4' img='https://img.youm7.com/ArticleImgs/2021/11/5/45223-%D9%88%D9%8A%D8%AC%D8%B2--(1).jpeg' />
           <Vedio varrrr='5' songName={<> &nbsp;  - للي </>} artistName='محمد منير ' fileName='3.mp4'poster='https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg' img='https://storage.googleapis.com/jarida-cdn/images/1653320027534964800/1653320263000/1280x960.jpg' />
           <Vedio varrrr='6' songName={<> &nbsp;  - خطيرة</>} artistName='رامي صبري ' poster='https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg'  fileName='2.mp4' img='https://storage.googleapis.com/jarida-cdn/images/1626532460809819400/1626536931000/640x480.jpg' />
         
           <Vedio varrrr='7' songName={<> &nbsp;  - في عشق البنات </>} artistName='محمد منير ' poster='https://i.ytimg.com/vi/dRh-XXDfe_M/maxresdefault.jpg'  fileName='4.mp4' img='https://storage.googleapis.com/jarida-cdn/images/1653320027534964800/1653320263000/1280x960.jpg' />
          </div>
          <Footer />
    </div>
  )
}

export default VediosPage