import React from 'react'
import { Link } from 'react-router-dom'

const Playlist = ({img,text}) => {
  return (
    <Link to='/genre'>
      <div className='playlist' >
      
      <div className='box' >
          <img src={img} />
           
      </div>
      <div className='text'>
      { text}
      </div>
     
          </div>
    </Link>  
    
  )
}

export default Playlist