import React from 'react'
import { Link } from 'react-router-dom'
import Gener from './Gener'

const Spiritual = () => {
  return (
    <div className='genres'>
    <Link to='/religion'><Gener newclass='gener-religion' text={ <>Top  Islamic</>}  img={'/images/spiritual/religious-icon-1.png'}/></Link>
    <Link to='/coptic'><Gener  newclass='gener-religion' text={<>Gospel  Hits</>} img={'/images/spiritual/religious-icon-4.png'} /></Link>
    <Link to='/religion'><Gener  newclass='gener-religion' text={<>ادعية الحرمين </>} img={'/images/spiritual/religious-icon-3.png'} /></Link>
    <Link to='/coptic'><Gener  newclass='gener-religion' text={ <>Gospel  Hits</>}  img={'/images/spiritual/religious-icon-2.png'}/></Link>
  
        
       
  </div>
  )
}

export default Spiritual