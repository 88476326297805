import React, { useState } from 'react'
import 'react-jinke-music-player/assets/index.css'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import Header from '../Resable/Header'
import Footer from '../Resable/Footer'
import Song from '../TopTones/Song'
import { GiMusicSpell } from 'react-icons/gi'
import { BsDisc } from 'react-icons/bs'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
const Religon = ({ songName='  محمود الحصري - سورة البلد' , artistName , fileName='Mahomud ElHosary-Sorat ElBalad'}) => {
    
       
    const [songTrack, setSongTrack] = useState(songName + '(Full Track)')
    const [songType, setSongType] = useState('mp3')
    const [song, setSong] = useState(fileName + '.mp3')

    const plyrProps = {
        source: {
            type: 'audio',
            title: 'Player',
          
            sources: [
              {
                src: `/mp3/${song}`,
                type: `audio/${songType}` ,
                },
               
             
            ],
          
        },
 
        options: {
            "controls": ['play-large', 'play'],
            "autoplay":true,
            
        }, // https://github.com/sampotts/plyr#options
        // Direct props for inner video tag (mdn.io/video)
       
     
        // loop: true,
        // playing: true,
        
        

    }
    return (
        <>
                <Header />
              <div className='player-page'>
            
                <div className='player-with-image '>
                    <img src='/images/spiritual/religious-cover-2.png' />
                    <div className='overlayer'></div>
                    <div className='player'>
                        <Plyr  {...plyrProps} enabled={true} /> 
                        <div className='song-info'>
                        {songTrack}
                        </div>
              
                        
                    </div>

                    
                </div>
       {/* start icons actions  */}
       <div className='icons-actions'>
                        <span className='icon' onClick={() => { setSong(fileName + 1 + '.wav'); setSongTrack(songName + ' tone 1'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone1</span>
                        </span>
                        <span className='icon' onClick={() => { setSong(fileName + 2 + '.wav'); setSongTrack(songName + ' tone 2'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone2</span>
                        </span>
                        {/* <span className='icon'>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>tone3</span>
                        </span> */}
                        <span className='icon' onClick={() => { setSong(fileName + '.mp3'); setSongTrack(songName + ' (Full Track)'); setSongType('mp3')}}>
                            <BsDisc />
                            <br />
                            <span className='tone-type'>Full Track</span>
                        </span>
                        <span className='icon oposite'>
                            <MdOutlinePhonelinkRing />
                            <br />
                            <span className='tone-type'>Assign</span>
                    </span>
                    </div>
                       {/* end icons actions  */}
                <div className='traks-num'>11 Tracks </div>
                <Song img={"https://gmuslim.com/wp-content/uploads/2019/12/75243108_574926636599929_4674808422732026068_n-780x470.jpg"} songName={" ما تيسر من سورة الزاريات"} artistName={"محمود الحصري"} />
                <Song img={"https://i1.sndcdn.com/artworks-000203326815-nxyzvx-t500x500.jpg"} songName={"ما تيسر من سورة الواقعة "} artistName={"عبد الباسط عبد الصمد"} />
                <Song img={"https://modo3.com/thumbs/fit630x300/9573/1641282454/%D9%85%D8%AD%D9%85%D8%AF_%D8%B5%D8%AF%D9%8A%D9%82_%D8%A7%D9%84%D9%85%D9%86%D8%B4%D8%A7%D9%88%D9%8A.jpg"} songName={"ما تيسر من سورة البقرة "} artistName={"المنشاوي"} />
                <Song img={"https://upload.wikimedia.org/wikipedia/ar/0/08/%D9%85%D8%AD%D9%85%D8%AF_%D9%85%D8%AD%D9%85%D9%88%D8%AF_%D8%A7%D9%84%D8%B7%D8%A8%D9%84%D8%A7%D9%88%D9%8A.jpeg"} songName={" ما تيسر من سورة الاعراف "} artistName={" الطبلاوي"} />
                <Song img={"https://cdn.sotor.com/thumbs/fit630x300/25556/1553804509/%D9%85%D8%B9%D9%84%D9%88%D9%85%D8%A7%D8%AA_%D8%B9%D9%86_%D8%A7%D9%84%D8%B4%D9%8A%D8%AE_%D9%85%D8%A7%D9%87%D8%B1_%D8%A7%D9%84%D9%85%D8%B9%D9%8A%D9%82%D9%84%D9%8A.jpg"} songName={" سورة الرحمن  "} artistName={"ماهر المعيقلي"} />
                <Song img={"https://i1.sndcdn.com/artworks-yqaFIhjTMlyQyFHL-5PmjKw-t500x500.jpg"} songName={"سورة الغاشية "} artistName={"ياسر الدوسري"} />
                <Song img={"https://storage.googleapis.com/jarida-cdn/images/1610382356981066500/1610386924000/640x480.jpg"} songName={" ما تيسر من سورة الطور "} artistName={"محمد البراك"} />
                <Song img={"https://gmuslim.com/wp-content/uploads/2019/12/75243108_574926636599929_4674808422732026068_n-780x470.jpg"} songName={"ما تيسر من سورة الكهف "} artistName={"محمود الحصري"} />
                <Song img={"https://modo3.com/thumbs/fit630x300/9573/1641282454/%D9%85%D8%AD%D9%85%D8%AF_%D8%B5%D8%AF%D9%8A%D9%82_%D8%A7%D9%84%D9%85%D9%86%D8%B4%D8%A7%D9%88%D9%8A.jpg"} songName={"سورة الطارق"} artistName={"المنشاوي"}  />
                <Song img={"https://i1.sndcdn.com/artworks-000203326815-nxyzvx-t500x500.jpg"} songName={"سورة النازعات "} artistName={"عبد الباسط عبد الصمد"} />
                <Song img={"https://gmuslim.com/wp-content/uploads/2019/12/75243108_574926636599929_4674808422732026068_n-780x470.jpg"} songName={"سورة الاعلي "} artistName={"محمود الحصري"} />
                
             
                
              </div>
            <Footer />
      </>
    
  )
}

export default Religon