import React, { useState,useEffect } from 'react'
import { GiMusicSpell } from 'react-icons/gi'
import { BsDisc } from 'react-icons/bs'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
import 'react-jinke-music-player/assets/index.css'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"


const Player = ({ songName , artistName , fileName}) => {
   
    const [songTrack, setSongTrack] = useState(songName + '(Full Track)')
    const [songType, setSongType] = useState('mp3')
    const [song, setSong] = useState(fileName + '.mp3')

    const plyrProps = {
        source: {
            type: 'audio',
            title: 'Player',
          
            sources: [
              {
                src: `/mp3/${song}`,
                type: `audio/${songType}` ,
                },
               
             
            ],
          
        },

        options:{  
            "autoplay": true,
         
            
        }, // https://github.com/sampotts/plyr#options
        // Direct props for inner video tag (mdn.io/video)
        controls: [
            'play-large', // The large play button in the center
            'restart', // Restart playback
            'rewind', // Rewind by the seek time (default 10 seconds)
            'play', // Play/pause playback
            'fast-forward', // Fast forward by the seek time (default 10 seconds)
            'progress', // The progress bar and scrubber for playback and buffering
            'current-time', // The current time of playback
            'duration', // The full duration of the media
            'mute', // Toggle mute
            'volume', // Volume control
            'captions', // Toggle captions
            'settings', // Settings menu
            'pip', // Picture-in-picture (currently Safari only)
            'airplay', // Airplay (currently Safari only)
            'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
            'fullscreen', // Toggle fullscreen
            'autoplay',
          ],
        autoplay:true,
        // loop: true,
        // playing: true,
        
        

    }
    console.log(song)

    useEffect(() => {
        setSongTrack(songName + ' (Full Track)')
        setSong(fileName + '.mp3')
    }, [fileName,songName]);
  return (
      <div className='player-box'>
          <div className='song-details'>
              <span className='artist-name'>{ artistName}</span> 
              <br />
              <span className='song-name'>{ songTrack}</span> 
           
              
          </div>
          <Plyr plyr-color-main={'red'} {...plyrProps}  autoplay={true} /> 
       
          <span className='icon' onClick={() => { setSong(fileName + 1 + '.wav'); setSongTrack(songName + ' tone 1'); setSongType('wav')}}>
              <GiMusicSpell />
              <br />
              <span className='tone-type'>Tone1</span>
          </span>
          <span className='icon' onClick={() => { setSong(fileName + 2 + '.wav'); setSongTrack(songName + ' tone 2'); setSongType('wav')}}>
              <GiMusicSpell />
              <br />
              <span className='tone-type'>Tone2</span>
          </span>
          {/* <span className='icon'>
              <GiMusicSpell />
              <br />
              <span className='tone-type'>tone3</span>
          </span> */}
          <span className='icon' onClick={() => { setSong(fileName + '.mp3'); setSongTrack(songName + ' (Full Track)'); setSongType('mp3')}}>
              <BsDisc />
              <br />
              <span className='tone-type'>Full Track</span>
          </span>
          <span className='icon oposite'>
              <MdOutlinePhonelinkRing />
              <br />
              <span className='tone-type'>Assign</span>
          </span>
   </div>
        
      
   
  )
}

export default Player