import React, { useState } from 'react'
import Footer from '../Resable/Footer'
import Header from '../Resable/Header'
import Player from './Player'
import Song from './Song'
import TopUpperSection from './TopUpperSection'

const TopTones = () => {
  const [playerSong, setPlayerSong] = useState('انت اختيار ')
  const [playerArtist, setPlayerArtist] = useState('تامر حسني ')
  const [playerFile, setPlayerFile] = useState('TamerHosny-EntaEkhtyar')
  
  const changeSong = (song, artist, file) => {
    setPlayerSong(song)
    setPlayerArtist(artist)
    setPlayerFile(file)
  }
  console.log(playerSong)
  return (
      <div className='top-tones'>
          <Header />
          <div className='body'>
              <TopUpperSection />
              <div className='grid'>
          <button className='orange'>Shuffle Play</button>
          <button className='gray'>Follow Playlist</button>
        </div>
        <Player songName={playerSong} artistName={playerArtist} fileName={playerFile} />
        <Song changeSong={changeSong}   img = {'https://i.ytimg.com/vi/T0AA8GvHzhY/maxresdefault.jpg'} songName = {'انت اختيار '} artistName = {"تامر حسني "} fileName='TamerHosny-EntaEkhtyar'/>
        <Song changeSong={changeSong}  img = {'https://i.ytimg.com/vi/bvbhfgXk_pY/maxresdefault.jpg'} songName = {'الفترة دي'} artistName = {"تامر عاشور"} fileName='TamerAshour-ElFatraDi'/>
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/svm9_UmQRls/maxresdefault.jpg"} songName = {"قلبي حبك جدا"} artistName = {"محمد حماقي"} fileName='Hamaki-AlbyHabbakGeddan' />
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName = {"البخت"} artistName = {"ويجز"} fileName='Wegz-ElBakht' />
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg"} songName = {"للي"} artistName = {"محمد منير"}fileName='MohamedMounir-Lelly' />
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName = {"خطيرة"} artistName = {"رامي صبري"}fileName='RamySabry-Khateera' />
              <Song changeSong={changeSong}  img = {"https://i.ytimg.com/vi/ULM9GvvJ370/maxresdefault.jpg"} songName = {"أنا غلطان"} artistName = {"بهاء سلطان"}fileName='' />
          </div>
          <Footer/>
    </div>
  )
}

export default TopTones