import React from 'react'
import ArtistCircle from './ArtistCircle'

const TopArtists = () => {
  return (
    <div className='artists-section'>
      <ArtistCircle img='https://storage.googleapis.com/jarida-cdn/images/1653320027534964800/1653320263000/1280x960.jpg' />
          <ArtistCircle img='https://cdns-images.dzcdn.net/images/artist/cecaa111b7a338c3004817cd09ae1339/500x500.jpg' />
          <ArtistCircle img='https://ichef.bbci.co.uk/news/640/cpsprodpb/179E5/production/_120814769_mediaitem120814281.jpg' />
          <ArtistCircle img='https://img.youm7.com/ArticleImgs/2020/1/16/204251-assala_official_80556379_511553119475835_7957521285310667589_n.jpg' />
          <ArtistCircle img='https://upload.wikimedia.org/wikipedia/commons/4/49/%D8%B4%D9%8A%D8%B1%D9%8A%D9%86.jpg' />
      <ArtistCircle img='https://media.gemini.media/img/Original/2022/7/17/2022_7_17_17_22_13_181.jpg' />
      <ArtistCircle img='https://forbesme-prestaging-media.s3.us-east-2.amazonaws.com/lists/uploads/2021/03/30150806/6-Angham_Twitter-%40Angham.jpg' />
      <ArtistCircle img='https://cdns-images.dzcdn.net/images/artist/cecaa111b7a338c3004817cd09ae1339/500x500.jpg' />
          <ArtistCircle img='https://ichef.bbci.co.uk/news/640/cpsprodpb/179E5/production/_120814769_mediaitem120814281.jpg' />
          <ArtistCircle img='https://img.youm7.com/ArticleImgs/2020/1/16/204251-assala_official_80556379_511553119475835_7957521285310667589_n.jpg' />
          <ArtistCircle img='https://storage.googleapis.com/jarida-cdn/images/1653320027534964800/1653320263000/1280x960.jpg' />
    </div>
  )
}

export default TopArtists