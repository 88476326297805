import React from 'react'
import ArtistCircle from '../homePage/ArtistCircle'
import Album from '../Resable/Album'
import Footer from '../Resable/Footer'
import Header from '../Resable/Header'
import Song from '../TopTones/Song'
import ArtistTop from './ArtistTop'
import Vedios from './Vedios'

const Artist = () => {
  return (
      <div className='artist-page'>
          <Header />
          <ArtistTop />
          <h5>Latest Tracks</h5>
          <Song img={"https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg"} songName={" للي "} artistName={"محمد منير"} />
      <Song img={"https://i.ytimg.com/vi/xbnkSzwiImE/maxresdefault.jpg"} songName={" زوق"} artistName={"محمد منير"} />
      <Song  img = {"https://i.ytimg.com/vi/cTxevJxmt_U/maxresdefault.jpg"} songName = {"هون يا ليل"} artistName = {"محمد منير"} />
          <h5>Albums</h5>
      <div className='albums grid-container'>
              <Album albumName='باب الجمال' date='2014' img='https://i.ytimg.com/vi/ldhi9BQxEYo/maxresdefault.jpg' />
              <Album albumName=' عمري عشرين' date='2005' img='https://www.qalimat.com/wp-content/uploads/2020/07/%D8%A7%D9%85%D8%A8%D8%A7%D8%B1%D8%AD-%D9%83%D8%A7%D9%86-%D8%B9%D9%85%D8%B1%D9%8A-%D8%B9%D8%B4%D8%B1%D9%8A%D9%86.jpg' />
              <Album albumName='وسط الدايرة' date='1987' img='https://i.ytimg.com/vi/I3x1LvJCAvY/hqdefault.jpg' />
              <Album albumName='باب الجمال' date='2014' img='https://i.ytimg.com/vi/ldhi9BQxEYo/maxresdefault.jpg' />
              <Album albumName=' عمري عشرين' date='2005' img='https://www.qalimat.com/wp-content/uploads/2020/07/%D8%A7%D9%85%D8%A8%D8%A7%D8%B1%D8%AD-%D9%83%D8%A7%D9%86-%D8%B9%D9%85%D8%B1%D9%8A-%D8%B9%D8%B4%D8%B1%D9%8A%D9%86.jpg' />
              <Album albumName='وسط الدايرة' date='1987' img='https://i.ytimg.com/vi/I3x1LvJCAvY/hqdefault.jpg' />
      </div>
      <h5>Music Videos</h5>
      <Vedios />
      <h5>Smilar Artists</h5>
      <div className='artists-section'>
        <ArtistCircle img='https://www.sayidaty.net/sites/default/files/styles/900_scale/public/2022-03/85476.jpeg' />
        <ArtistCircle img='https://i.discogs.com/VVpO8WYYXEnPJnu-o3vo4MMv9u4iZBuq2uaEXMun7Mo/rs:fit/g:sm/q:40/h:300/w:300/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9BLTMxMTE1/OTctMTM2MzY5MjAw/MC04OTU1LmpwZWc.jpeg' />
          <ArtistCircle img='https://media.elcinema.com/uploads/_315x420_a74a50435d2026d7f328b6b19430ef7cde37bde0ec8d41dee7568b32183f1956.jpg' />
          <ArtistCircle img='https://www.hiamag.com/sites/default/files/article/05/03/2019/7831426-694666318.jpeg' />
          <ArtistCircle img='https://storage.googleapis.com/jarida-cdn/images/1658761936471905200/1658763402000/640x480.jpg' />
          <ArtistCircle img='https://media.gemini.media/img/Original/2022/7/17/2022_7_17_17_22_13_181.jpg' />
          <ArtistCircle img='https://forbesme-prestaging-media.s3.us-east-2.amazonaws.com/lists/uploads/2021/03/30150806/6-Angham_Twitter-%40Angham.jpg' />
          <ArtistCircle img='https://cdns-images.dzcdn.net/images/artist/cecaa111b7a338c3004817cd09ae1339/500x500.jpg' />
          <ArtistCircle img='https://ichef.bbci.co.uk/news/640/cpsprodpb/179E5/production/_120814769_mediaitem120814281.jpg' />
          <ArtistCircle img='https://img.youm7.com/ArticleImgs/2020/1/16/204251-assala_official_80556379_511553119475835_7957521285310667589_n.jpg' />
         
    </div>
          <Footer />
    </div>
  )
}

export default Artist