import React, {useRef, useEffect, useState} from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { AiOutlineHeart } from 'react-icons/ai'
// use ref  function 
function useOutsideAlerter(ref) {
  useEffect(() =>{
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        
       ref.current.firstChild.pause();
        console.log('hi')
      
   
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    
  }}, [ref]);
}
function useOutsideList(ref,setShow) {
  useEffect(() =>{
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
     
        setShow(false)
    
      
   
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    
  }}, [ref]);
}

const Vedio = ({ songName, artistName, fileName, img, poster, key }) => {
     // close list when click any where
     const wrapperRef = useRef(null);
     const listRef = useRef(null);
     
  
  const [show, setShow] = useState(false)
  useOutsideAlerter(wrapperRef);
  useOutsideList(listRef, setShow);
  return (
    <div  className='video' ref={wrapperRef}  >
      <video key={key} id='myVideo' controls loop controlsList="nodownload" poster={poster} onPlay={()=>{document.getElementById('header').click(); console.log('hirrrrrrrrrrrrr')}}>
        <source src={`/videos/${fileName}`} type="video/mp4" />
        
      </video>
      <div className='details' id='details'>
          <img src={img} />
           <div className='artist-name'>{ artistName}</div>
        <div className='song-name'>{songName}</div>
        {img&&<span className='oposite' onClick={()=>setShow(!show)}>
         <FiMoreVertical />
        </span>}
        {show && <div className='list'  ref={listRef} >
                <div className='list-option'><span className='icon'><AiOutlineHeart /></span> Like</div>
                <div className='list-option'>Add to playlist</div>
                <div className='list-option'>Share</div>
                <div className='list-option'>Assign as call tone</div>
            </div>}
      </div>
       

         
    </div>
  )
}

export default Vedio