import React, { useState,useRef, useEffect } from 'react'
import { BsList } from 'react-icons/bs'
import { FaUserEdit } from 'react-icons/fa'
import { AiOutlineCloudDownload, AiFillHeart } from 'react-icons/ai'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
import { Link } from 'react-router-dom'

// use ref  function 
function useOutsideAlerter(ref,icon,setList) {
    useEffect(() =>{
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)&&icon.current && !icon.current.contains(event.target)) {
            setList(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      
    }}, [ref]);
  } 
const Header = ({ profile = false }) => {
    const [list, setList] = useState(false)
     // close list when click any where
  const wrapperRef = useRef(null);
  const icon = useRef(null);
  useOutsideAlerter(wrapperRef,icon, setList);
  console.log(list)
  return (
      <div className='header'id='header'>
          <Link to='/'></Link>
          <span className='text'>
              Hello 
              <br />
              Mohamed
          </span>
           <div className='oposite'>
              {!profile && <Link to='/profile'>
                  <span className='icon'>
                      <FaUserEdit />
                  </span>
              </Link>}
      
                <span className='icon' ref={icon}  onClick={()=>setList(!list)} >
                    <BsList />
                </span>
          
              
              {list && <div className='list'   ref={wrapperRef}>
                  <div><span className='icon'><AiFillHeart /></span>Likes</div>
                  <div><span className='icon'><AiOutlineCloudDownload /></span>Downloads</div>
                  <div><span className='icon'><MdOutlinePhonelinkRing /></span>Call Tones</div>
                
             </div>}
              
          </div>
          
     </div>
  )
}

export default Header
