import React, { useState } from 'react'
import 'react-jinke-music-player/assets/index.css'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import Header from '../Resable/Header'
import Footer from '../Resable/Footer'
import Song from '../TopTones/Song'
import { GiMusicSpell } from 'react-icons/gi'
import { BsDisc } from 'react-icons/bs'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
const Player =({ songName='khatera' , artistName , fileName='RamySabry-Khateera'}) => {
    
       
    const [songTrack, setSongTrack] = useState(songName + '(Full Track)')
    const [songType, setSongType] = useState('mp3')
    const [song, setSong] = useState(fileName + '.mp3')

    const plyrProps = {
        source: {
            type: 'audio',
            title: 'Player',
          
            sources: [
              {
                src: `/mp3/${song}`,
                type: `audio/${songType}` ,
                },
               
             
            ],
          
        },
 
        options: {
            "controls": ['play-large', 'play'],
            "autoplay":true,
            
        }, // https://github.com/sampotts/plyr#options
        // Direct props for inner video tag (mdn.io/video)
       
     
        // loop: true,
        // playing: true,
        
        

    }
    return (
        <>
                <Header />
              <div className='player-page'>
            
                <div className='player-with-image'>
                    <img src='https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg' />
                    <div className='overlayer'></div>
                    <div className='player'>
                        <Plyr  {...plyrProps} enabled={true} /> 
                        <div className='song-info'>
                           Ramy sabry -  {songTrack}
                        </div>
                    </div>

                    
                </div>
                {/* start icons actions  */}
                <div className='icons-actions'>
                        <span className='icon' onClick={() => { setSong(fileName + 1 + '.wav'); setSongTrack(songName + ' tone 1'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone1</span>
                        </span>
                        <span className='icon' onClick={() => { setSong(fileName + 2 + '.wav'); setSongTrack(songName + ' tone 2'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone2</span>
                        </span>
                        {/* <span className='icon'>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>tone3</span>
                        </span> */}
                        <span className='icon' onClick={() => { setSong(fileName + '.mp3'); setSongTrack(songName + ' (Full Track)'); setSongType('mp3')}}>
                            <BsDisc />
                            <br />
                            <span className='tone-type'>Full Track</span>
                        </span>
                        <span className='icon oposite'>
                            <MdOutlinePhonelinkRing />
                            <br />
                            <span className='tone-type'>Assign</span>
                    </span>
                    </div>
                {/* end icons actions  */}
                <Song img={"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName={"khateera"} artistName={"Ramy sabry"} />
                <div className='more-songs '>
                    <div className='more-title'>More From Ramy Sabry</div>
                    
                    <Song img={"https://i.ytimg.com/vi/9XUipv3vmeY/maxresdefault.jpg"} songName={"shatbna"} artistName={"Ramy sabry"} />
                    <Song img={"https://cdn.elwatannews.com/watan/840x473/3747711551656597918.jpg"} songName={"Genan"} artistName={"Ramy sabry"} />
                    <Song img={"https://i.ytimg.com/vi/Ievv62L0cXw/maxresdefault.jpg"} songName={"hayaty mesh tmam"} artistName={"Ramy sabry"} />
                    <Song img={"https://i.ytimg.com/vi/SPQKKDmBQnQ/maxresdefault.jpg"} songName={"Hya"} artistName={"Ramy sabry"} />
                    <Song img={"https://i.ytimg.com/vi/z1NwNSVOSSo/maxresdefault.jpg"} songName={"Lsa Yama"} artistName={"Ramy sabry"} />
                    <Song img={"https://i.ytimg.com/vi/0bGrkkanwQE/maxresdefault.jpg"} songName={"Mosh Fare2"} artistName={"Ramy sabry"} />
                </div>
             
                
             
                
              </div>
            <Footer />
      </>
    
  )
}

export default Player