import React from 'react'
import Footer from '../Resable/Footer'
import Header from '../Resable/Header'
import Playlist from './Playlist'

const Playlists = () => {
  return (
      <div className='playlists'>
          <Header />
          <div className='title'>Playlists </div>
          <div className='container'>
              <Playlist  text={ <>Arabic <br/> Pop</>} img={'/images/playlists/icon-2.png'}/>
              <Playlist text={<>Shaaby <br /> Music</>} img={'/images/playlists/icon-1.png'} />
              <Playlist  text= {<>Arabic <br/> Rap</>}  img={'/images/playlists/icon-3.png'}/>
              
                <Playlist text={ <>WorkOut <br/>..</>} img={'/images/playlists/icon-4.png'} />
                <Playlist  text= {<>Love <br/> ..</>}  img={'/images/playlists/icon-5.png'}/>
                <Playlist  text={ <>Sad <br/> Mood</>} img={'/images/playlists/icon-6.png'}/>
             
                <Playlist  text={ <>Music <br/> Only</>} img={'/images/playlists/icon-1.png'}/>
                <Playlist  text={ <>Dance<br/> ..</>} img={'/images/playlists/icon-2.png'}/>
               
          </div>
        <Footer />
    </div>
  )
}

export default Playlists