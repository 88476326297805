import React, { useState } from 'react'
import 'react-jinke-music-player/assets/index.css'
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import Header from '../Resable/Header'
import Footer from '../Resable/Footer'
import Song from '../TopTones/Song'
import { GiMusicSpell } from 'react-icons/gi'
import { BsDisc } from 'react-icons/bs'
import {MdOutlinePhonelinkRing} from 'react-icons/md'
const Coptics = ({ songName=' مريم حلمي - وفيما اظنه لا يستجيب' , artistName , fileName='femaazonhlaystgeeb'}) => {
    
       
    const [songTrack, setSongTrack] = useState(songName + '(Full Track)')
    const [songType, setSongType] = useState('mp3')
    const [song, setSong] = useState(fileName + '.mp3')

    const plyrProps = {
        source: {
            type: 'audio',
            title: 'Player',
          
            sources: [
              {
                src: `/mp3/coptics/${song}`,
                type: `audio/${songType}` ,
                },
               
             
            ],
          
        },
 
        options: {
            "controls": ['play-large', 'play'],
            "autoplay":true,
            
        }, // https://github.com/sampotts/plyr#options
        // Direct props for inner video tag (mdn.io/video)
       
     
        // loop: true,
        // playing: true,
        
        

    }
    return (
        <>
                <Header />
              <div className='player-page'>
            
                <div className='player-with-image '>
                    <img src='/images/spiritual/religious-cover-1.png' />
                    <div className='overlayer'></div>
                    <div className='player'>
                        <Plyr  {...plyrProps} enabled={true} /> 
                        <div className='song-info'>
                       {songTrack}  
                        </div>
              
                        
                    </div>

                    
                </div>
       {/* start icons actions  */}
       <div className='icons-actions'>
                        <span className='icon' onClick={() => { setSong(fileName + 1 + '.wav'); setSongTrack(songName + ' ( tone 1)'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone1</span>
                        </span>
                        <span className='icon' onClick={() => { setSong(fileName + 2 + '.wav'); setSongTrack(songName + ' (tone 2)'); setSongType('wav')}}>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>Tone2</span>
                        </span>
                        {/* <span className='icon'>
                            <GiMusicSpell />
                            <br />
                            <span className='tone-type'>tone3</span>
                        </span> */}
                        <span className='icon' onClick={() => { setSong(fileName + '.mp3'); setSongTrack(songName + ' (Full Track)'); setSongType('mp3')}}>
                            <BsDisc />
                            <br />
                            <span className='tone-type'>Full Track</span>
                        </span>
                        <span className='icon oposite'>
                            <MdOutlinePhonelinkRing />
                            <br />
                            <span className='tone-type'>Assign</span>
                    </span>
                    </div>
                       {/* end icons actions  */}
                <div className='traks-num'>11 Tracks </div>
                <Song img={"https://i1.sndcdn.com/artworks-RMjAtpzOC6Z5yVxS-W8vkcw-t500x500.jpg"} songName={"وفيما اظنه لا يستجيب"} artistName={"مريم حلمي"} />
                <Song img={"https://i1.sndcdn.com/artworks-rTTANg9bg731GW0z-PTgq4w-t500x500.jpg"} songName={"هنخاف من ايه"} artistName={"فريق الخبر السار"} />
                <Song img={"https://i.ytimg.com/vi/k76ejZyqYeE/maxresdefault.jpg"} songName={"اسمها مريم"} artistName={"فريق المس ايدينا"} />
                <Song img={"https://i.ytimg.com/vi/jy149CDzsyU/maxresdefault.jpg"} songName={" فمررت بك  "} artistName={"فريق الخبر السار"} />
                <Song img={"https://i.ytimg.com/vi/RaNmlW8BVUI/maxresdefault.jpg"} songName={"الهنا عظيم الهنا امين"} artistName={"اسحق كرمي"} />
                <Song img={"https://i.ytimg.com/vi/iPzxoFLnd9w/maxresdefault.jpg"} songName={"انا شاعر بيك"} artistName={"فريق الخبر السار"} />
                <Song img={"https://i.ytimg.com/vi/pfCDmOXOfko/hqdefault.jpg"} songName={"مش قصة وخلاص"} artistName={"فريق المخلص"} />
                <Song img={"https://i.ytimg.com/vi/n3BwHaXQXgE/mqdefault.jpg"} songName={"فرحان بيك"} artistName={"اسعد انور"} />
                <Song img={"https://c-fa.cdn.smule.com/rs-s30/arr/48/03/7a3ef442-b57b-4dcf-a8d0-f18a8cd9828e.jpg"} songName={"بارك بلادي"} artistName={"زياد شحادة"} />
                <Song img={"https://i.ytimg.com/vi/GtHoRMGu2SI/mqdefault.jpg"} songName={"يا كنيسة"} artistName={"فريق الوعد"} />
                
             
                
              </div>
            <Footer />
      </>
    
  )
}
export default Coptics