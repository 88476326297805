import React from 'react'
import Song from '../Resable/Song'
import { Link } from 'react-router-dom'
const TopTones = () => {
  return (
    <div className='songs-section'>
    <div className='title'>Top Songs </div>
      <div class="grid-container">
              <Song  img = {"https://i.ytimg.com/vi/UXcIOz_8Ojo/maxresdefault.jpg"} songName = {"خطيرة"} artistName = {"رامي صبري"} />
              <Song  img = {"https://i.ytimg.com/vi/svm9_UmQRls/maxresdefault.jpg"} songName = {"قلبي حبك جدا"} artistName = {"محمد حماقي"} />
              <Song  img = {"https://i.ytimg.com/vi/pfRZomobZdQ/maxresdefault.jpg"} songName = {"للي"} artistName = {"محمد منير"} />
              <Song  img = {"https://i.ytimg.com/vi/ULM9GvvJ370/maxresdefault.jpg"} songName = {"أنا غلطان"} artistName = {"بهاء سلطان"} />
              <Song   />
        <Song img={'https://i.ytimg.com/vi/bvbhfgXk_pY/maxresdefault.jpg'} songName={'الفترة دي'} artistName={"تامر عاشور"} />
        <Song img={"https://i.ytimg.com/vi/_RHIECWv728/maxresdefault.jpg"} songName={"البخت"} artistName={"ويجز"} />
        
             
    </div>
</div>
  )
}

export default TopTones