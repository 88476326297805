import React from 'react'

const Gener = ({text ,img, color,newclass}) => {
  return (
      <div  className={`gener ${newclass}`} >
          <div className='box' >
              <img src={img} />
               
          </div>
          <div className='text'>
          { text}
          </div>
         
      </div>
  )
}

export default Gener