import React from 'react'
import { Link } from 'react-router-dom'
import Song from '../Resable/Song'
const InternationalMusic = () => {
  return (
    <div className='songs-section'>
   <div className='title'> International Music </div>
          <div class="grid-container">
          <Song  img = {"https://hips.hearstapps.com/hmg-prod/images/adele-performs-on-stage-as-american-express-present-bst-news-photo-1656785679.jpg?crop=1.00xw:0.771xh;0,0.0505xh&resize=640:*"} artistName = {""} songName = {"Adele"} />
          <Song  img = {"https://i.scdn.co/image/ab67616d00001e028863bc11d2aa12b54f5aeb36"} artistName = {""} songName = {"The weekend"} />
          <Song img={"https://upload.wikimedia.org/wikipedia/en/d/df/Say_So_-_Doja_Cat.png"} artistName={""} songName = {"Doja Cat"} />
         <Song img={"https://upload.wikimedia.org/wikipedia/commons/b/b5/191125_Taylor_Swift_at_the_2019_American_Music_Awards_%28cropped%29.png"} artistName={""} songName={"Taylor Swift"} />
     
        
             
        <Song  img = {"https://i.ytimg.com/vi/VElre5GhyV4/maxresdefault.jpg"} artistName = {""}  songName ={"Dua Lipa"} />
        <Song  img = {'https://i.pinimg.com/originals/c7/7d/ea/c77dea163e03786170a1be31d22f3412.jpg'} artistName = {""} songName = {"Michael Jackson"} />

       
</div>
</div>
  )
}

export default InternationalMusic