import React from 'react'
import { Link } from 'react-router-dom'

const MostRecentSongs = ({img,songName,artistName}) => {
  return (
    <Link to='/player'>
         <div className='mostrecent-song'>
            <img src={img}  alt='recent-song'/>
        </div>
    </Link>
 
  )
}

export default MostRecentSongs